import React from 'react'
import { graphql } from 'gatsby'
import { SwedishCookiesInfo } from '@accodeing/gatsby-theme-heimr'

import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'

export default ({ data }) => (
  <Layout pageTitle='Cookies' siteImage={data.header}>
    <Header fluidImage={data.header.childImageSharp.fluid} />
    <oma-grid-row>
      <SwedishCookiesInfo />
    </oma-grid-row>
    <Footer />
  </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "sheep.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
